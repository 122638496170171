






































































@import '@design';

.menu-section--title {
  font-size: 60px;
  line-height: 0.7;
}
.menu-section {
  &.callout {
    max-width: 300px;
    margin-right: auto;
    margin-left: auto;
    border: 4px solid $color-db-purple;
    border-radius: 6px;
    .menu-section--items {
      margin: 1em 0;
    }
    .callout-title--icon {
      height: 48px;
      margin: -3px 12px -3px 0;
    }
  }
}
